export class SignalRHandler {
    constructor(pOptions = { onMessage: null, logging: false, groupName: null }) {
        this.priv = {
            groupName: pOptions?.groupName,
            logging: pOptions?.logging ?? false,
            connection: null,
            defaultCallback : pOptions?.onMessage,
            callbacks: {}
        };
    }

    async connect(){
        try {
            this.#getConnection().on('SendMessageAsync',(type, message) => {
                this.#messageReceived(type, message);
            });
            await this.#getConnection().start({ transport: 'webSockets' });
            console.assert(this.#getConnection().state === signalR.HubConnectionState.Connected);
            console.log("SignalR Connected.");
        } catch(e){
            console.assert(this.#getConnection().state === signalR.HubConnectionState.Disconnected);
            console.warn(e);
            var that = this;
            var that = this;
            setTimeout(() => that.connect(), 2000);
        }       
    }

    #tryGetJson(message) {
        try {
            if (message && message != null) {
                return JSON.parse(message);
            }
            return message;
        } catch (e) {
            return message;
        }
    }

    #tryGetMessageText(message) {
        try {
            if (typeof(message) == 'object') {
                return JSON.stringify(message);
            }
            return message;
        } catch (e) {
            return message;
        }
    }

    #messageReceived(type,message){
        var msgObj = this.#tryGetJson(message);
        if(this.priv.logging){
            console.log('[' + new Date().toJSON() +'] Debug: Message received: ', type, msgObj);
        }
        if(typeof(this.priv.callbacks[type]) == 'function'){
            this.priv.callbacks[type](msgObj);
        } else if(typeof(this.priv.defaultCallback) == "function"){
            this.priv.defaultCallback(type, msgObj);
        } else if(this.priv.logging){
            console.warn('[' + new Date().toJSON() +'] Debug: No nessage callback registered for type: ', type,);
        } 
    }

    #getConnection(){
        if(this.priv.connection == null){
            this.priv.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.#getGroupName(), {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(this.priv.logging ? signalR.LogLevel.Debug : signalR.LogLevel.None)
                .withAutomaticReconnect()
                .build();
        }
        return this.priv.connection;
    }

    #getGroupName(){
        if(typeof(this.priv.groupName) === "function"){
            return this.priv.groupName();
        }
        if(this.priv.groupName == null){
            this.priv.groupName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        }
        return ("/nt/api/hub/" + this.priv.groupName).toLowerCase();
    }

    sendMessage(type, message) {
        try {            
            var connected = this.#getConnection().state == signalR.HubConnectionState.Connected;
 
            if(this.priv.logging){
                if(connected)
                    console.log('[' + new Date().toJSON() +'] Debug: Message sending: ', type, message);
                else 
                    console.warn('[' + new Date().toJSON() +'] Debug: Not connected.');
            } else if (!connected) return;
            
            var msgString = this.#tryGetMessageText(message);
            this.#getConnection().invoke('SendMessageAsync', type, msgString);
        } catch (e) {
            console.log(e);            
        }        
    }

    onMessageReceived(action) {
        this.priv.defaultCallback = action;
    }

    abort() {
        this.#getConnection().abort();
    }

    on(type, listener) {
        return this.priv.callbacks[type] = listener;
    }

    off(type, listener) {
        delete this.priv.callbacks[type];
    }
}